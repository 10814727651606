.container_2 {
    /* background-color: blue; */
    display: flex;
    flex-direction: column;  /* Stack items vertically */
    justify-content: center;  /* Center items horizontally */
    align-items: center;  /* Center items vertically */
    height: 100vh;
    width: 100vh;
    z-index:3;
    overflow-y: auto;
}
.pre_order_img_container {
    /* background-size: contain; */
    position: relative;
    margin-top: 420px;
    display: flex;
    justify-content: center;
    align-items: center; 
    height: 1200px;
    width: 1000px;
    /* background-color: blueviolet; */
    z-index:4;
}
@media screen and (max-width: 600px) {
    .pre_order_img_container {
      height: 800px;
      width: 450px;
      margin-top: 100px;
      margin-left: 100px;
      margin-right: 100px; 
    }
}
#pre_order_img {
    height: 100%;
    width: 75%;
}
@media screen and (max-width: 600px) {
    #pre_order_img {
        height: 100%;
        width: 90%;
    }
}
.para {
    font-family: "GentiumBKR";
    position: absolute;
    height: 400px;
    width: 525px;
    margin-top: -3%;
    top: 50%;  /* Adjust this value to position the text vertically */
    left: 50%;  /* Adjust this value to position the text horizontally */
    transform: translate(-50%, -50%);  /* Center the text */
    /* background-color: blue; */
    /* padding: 20px;  */
    z-index: 5;
    font-size: 23px;
}
@media screen and (max-width: 600px) {
    .para {
        margin-top: -10%;
        height: 200px;
        width: 325px;
        font-size: 16px;  /* Adjust font size for smaller screens if needed */
    }
}
.radio-buttons input[type="radio"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0; /* Set border-radius to 0 to make squares */
    width: 15px; /* Set the width of the square */
    height: 15px; /* Set the height of the square */
    border: 2px solid black; /* Set the border color */
    outline: none; /* Remove default focus styles */
    position: relative; /* Set position to relative for pseudo-element positioning */
    transition: background-color 0.3s; /* Add a smooth transition effect */
} 
.radio-buttons input[type="radio"]:checked {
    background-color: none; /* Change background color when checked */
}
.radio-buttons input[type="radio"]::before {
    content: '\2713'; /* Unicode check mark character */
    font-size: 16px; /* Set font size for the check mark */
    color: red; /* Set color for the check mark */
    position: absolute; /* Set position to absolute for positioning within the radio button */
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Center the check mark */
    opacity: 0; /* Initially hide the check mark */
    transition: opacity 0.3s; /* Add a smooth transition effect for the check mark */
}
.radio-buttons input[type="radio"]:checked::before {
    opacity: 1; /* Make the check mark visible when radio button is checked */
}
.radio-buttons label {
    margin-bottom: 10px; /* Add space between radio buttons and labels */
}
.radio-buttons {
    /* background-color: blue; */
    width: 400px;
    position: absolute;
    margin-top: 350px;
    font-size: 20px;
    z-index: 5;
} 
@media screen and (max-width: 600px) {
    .radio-buttons {
      width: 300px;
      position: absolute;
      margin-top: 300px;
      font-size: 20px;
      z-index: 5;
    }
} 
#a {
    margin-left: 20px;
} 
@media screen and (max-width: 600px) {
    #a {
      margin-left: 20px;
    }
  }
  
#b {
    margin-left: 160px;
}
@media screen and (max-width: 600px) {
    #b {
      margin-left: 60px;
    }
  } 
.proceed_button {
    font-family: "GentiumBKR";
    width: 135px;
    height: 50px;
    font-size: 36px;
    background-color:  #886748;
    color: white;
    position: absolute;
    margin-top: 500px;
}
@media screen and (max-width: 600px) {
    .proceed_button {
        /* background-color: blue; */ 
        position: absolute;
        margin-top: 390px;
        width: 100px;
        height: 30px;
        font-size: 22px;
    }
}
.proceed_button:hover {
    background-color: #59451d;
    /* color: #886748;  */
}