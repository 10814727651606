/* CSS reset */
/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
} */
@font-face {
  font-family: "Hellen";
  src: url("../font/ancienthellenic-webfont.ttf");
}
@font-face {
  font-family: "Gentium-R";
  src: url("../font/GentiumPlus-TT-R.ttf");
}
@font-face {
  font-family: "Gentium-I";
  src: url("../font/GentiumPlus-TT-I.ttf");
}
@font-face {
  font-family: "GentiumBKR";
  src: url("../font/GenBkBasR.ttf");
}
::-webkit-scrollbar {
  width: .1px; /* width of the scrollbar */
  height: .1px;
}

::-webkit-scrollbar-thumb {
  background-color: black; /* color of the draggable thumb */
  border-radius: 1px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #886748;; /* color of the draggable thumb on hover */
}

::-webkit-scrollbar-track {
  background-color: black;/* color of the track */
  border-radius: 1px;
}
body {
  scroll-behavior: smooth;
  margin: 0;
  font-size: 2vw;
  height: 100%;
  width: 100%;
  overflow: auto; 
}
.bark-background {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url('../images/bark_seamless_v2.jpg'); 
}
.pseudo-background {
  /* background-attachment:fixed; */
  margin: 0 auto; 
  /* display: flex;
  align-items: center; */
  /* justify-content: center; */
  position: fixed;
  width: 960px;
  height: 100%;
  z-index: 0;
  /* background-image: url('../images/godseye-sm.jpg'); */
  /* background-image: url('../images/background_1.png');  */
  background-image: url('../images/background_2.jpg');  
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top; 
}
.logo_container {
  /* background-color: blueviolet; */
  height: 182px; 
  width: 250px;
  margin-top: 4%;
  margin-left:35% ;
  margin-right:35% ;
}
@media screen and (max-width: 600px) {
  .logo_container {
  height: 146px; 
  width: 200px;
  margin-left:28% ;
  margin-right:28% ;
    
  }
}
.logo_img {
  height: 90%; 
  width: 90%;
}
.container {
  /* background-color: #D6A73A; */
  /* margin: 0 auto !important; */
  margin: 0 auto;
  padding: 0;
  /* font-size: 1.8vw; */
  height: 100%;
  width: 960px;
  z-index: 1;
  overflow: auto; /* or overflow: visible; */
}
.paragraphs {
  color: #886748;
  /* font-family: "Hellen"; */
  font-family: "Gentium-R";
  /* font-family: "Gentium-I"; */
  /* font-family: "GentiumBKR"; */
  /* font-family: sans-serif; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-left: 30%;
  margin-right: 30%; */
  margin-top: 10px;
  /* display: flex; */
  /* background-color: blueviolet; */
}
@media screen and (max-width: 600px) {
  .paragraphs {
    margin-top: 10px;
    
  }
}
#para_about,
#para_author, 
#para_contact {
  margin: .2%;
  font-size: 30px;
  /* font-size: 1em;  */
  /* background-color: aliceblue; */
}
@media screen and (max-width: 600px) {
  #para_about,
  #para_author,
  #para_contact {
    font-size: 28px;
    margin: 2%; 
  }
}
.paragraphs a {
  text-decoration: none; /* Remove underlines from links */
  color: #886748;
} 
.paragraphs a:hover {
  background-color: transparent;
  color: #59451d;
}
.ss_cover_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 600px;
  height: 916px;
  /* margin-top: 50px; */
  margin: 0 auto;
  /* background-color: blue; */
  z-index: 1;
}
/* .hover-effect {
  transition: filter 0.8s ease; /* Add a smooth transition on hover */

/* .hover-effect:hover {
  filter: brightness(75%); /* Darken the image */
#ss_cover_img {
  margin-top: 75px;
  height: 100%; 
  width: 100%;
  /* nordic */
  box-shadow: 5px 5px 15px black; 
  /* box-shadow: 5px 5px 15px #777775;  */
  /* box-shadow: 10px 5px 5px black; */
  z-index: 1;
  /* background-color: blue; */
}
@media only screen and (max-width: 600px) {
  .ss_cover_container {
    margin-top: -3%;
    width: 325px;
    height: 475px;
  }
  #ss_cover_img {
    height: 100%; 
    width: 100%; 
  }
}
.pre_order_container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  /* margin-left: 30%;
  margin-right: 30%; */
  /* background-color: blueviolet; */
  box-shadow: 0 0 10px transparent;
  z-index: 1;
}
.pre_order_container a {
  text-decoration: none; /* Remove underlines from links */
  color: #886748;
} 
.pre_order_container a:hover {
  background-color: transparent;
  color: #59451d;
}
#para_purchase {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: blue; */
  color: #886748;
  font-size: 30px;
  font-family: "Gentium-R";
  text-decoration: none; /* Remove underlines from links */
  /* nordic */
  text-shadow: 5px 5px 15px black; 
  /* text-shadow: 0px 0px 10px rgba(197, 189, 176, 1); */
  cursor: pointer; 
  z-index: 1; 
  margin-right: 5%;
} 
#jju98j {
  

}
@media screen and (max-width: 600px) {
  #para_purchase {
    font-size: 30px; 
  }
}
#para_pre_order {
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: yellow; */
    color: #886748;
    font-size: 30px;
    font-family: "Gentium-R";
    text-decoration: none; /* Remove underlines from links */
    /* nordic */
    text-shadow: 5px 5px 15px black; 
    /* text-shadow: 0px 0px 10px rgba(197, 189, 176, 1); */
    cursor: pointer; 
    z-index: 1; 
    margin-left: 5%;
}
@media screen and (max-width: 600px) {
  #para_pre_order {
    font-size: 30px; 
  }
}
#ghortj {
  
}
#TOOL_TIP {
  color: #886748;
  font-size: 18px;
    /* margin: .5%;  */
}
@media screen and (max-width: 600px) {
  #TOOL_TIP {
    font-size: 10px;
    /* margin: .5%;  */
  }
}
.purchase_paragraphs {
  /* text-decoration: none; */
  color: #d1c0ac;
  /* font-family: "Hellen"; */
  font-family: "Gentium-R";
  /* font-family: "Gentium-I"; */
  /* font-family: "GentiumBKR"; */
  /* font-family: sans-serif; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-left: 30%;
  margin-right: 30%; */
  margin-top: -15px;
  /* display: flex; */
  /* background-color: blueviolet; */
  /* text-decoration: line-through; */
}
.para_ebook {
  /* text-decoration: line-through; */
  text-decoration: none;
}
#para_ebook {
  margin: .2%;
  font-size: 16px;
  text-shadow: 2px 2px 15px #000;
  /* font-size: 1em;  */
  /* background-color: aliceblue; */
}
.para_audiobook {
  text-decoration: line-through;
}
#para_audiobook {
  margin: .2%;
  font-size: 16px;
  text-shadow: 2px 2px 15px #000;
  /* font-size: 1em;  */
  /* background-color: aliceblue; */
}
.para_print {
  text-decoration: line-through;
}
#para_print {
  margin: .2%;
  font-size: 16px;
  text-shadow: 2px 2px 15px #000;
  /* font-size: 1em;  */
  /* background-color: aliceblue; */
}
@media screen and (max-width: 600px) {
  #para_ebook,
  #para_audiobook,
  #para_print {
    font-size: 22px;
    margin: 2%; 
  }
}
#para_ebook,
#para_audiobook,
#para_print {
  /* text-decoration: none;  */
  color: #d1c0ac;
}
#para_ebook:hover{
  background-color: transparent;
  color: #BF9534;
  cursor: pointer; 
}
#para_audiobook:hover {
  background-color: transparent;
  color: #BF9534; 
}
#para_print:hover {
  background-color: transparent;
  color: #BF9534;
}
.header {
  z-index: 2;
  margin-top: 10%; 
  background-image: url("../images/seamless-frame_75res_full.png");
  background-color: transparent;
  background-size: contain;
  min-height: 40px; /* Set a minimum height */
  /* margin: 0px; */
}
#contents {
  
}
.about_section {

}
.author_section {
  /* margin-top: 10%; */

}
.contact_section {

}
section > h2 {
  font-family: "Hellen";
  background-color: #d1c0ac;
  text-align: right;
  height: 75px;
  padding-right: 150px;
  margin: 0px;
  font-size: 24px;
}
@media screen and (max-width: 600px) {
  section > h2 {
    font-size: 30px;
  }
}
section > p {
  font-family: "GentiumBKR";
  background-color: #d1c0ac;
  text-align: left;
  margin: 0 auto;
  padding-left: 100px;
  padding-right: 50px;
  padding-bottom: 10px;
  font-size: 16px;
}
@media screen and (max-width: 600px) {
  section > p {
    font-size: 22px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 10px;
  }
}
@media screen and (max-width: 600px) {
  #x {
    font-size: 30px;
  }
}
.email_form {
  /* background-color: blueviolet; */
  height: 46px;
  display: flex;
  /* align-items: center; */
  position: relative; 
}
@media screen and (max-width: 600px) {
  .email_form {
  height: 60px;
  }
}
#email_input {
  margin-top: 5px;
  display: flex;
  align-items: center;
  height: 14px;
  width:300px;
}
@media screen and (max-width: 600px) {
  #email_input {
  display: flex;
  align-items: center;
  height: 22px;
  width:125px;
  }
}
section > input {
	margin-bottom: 100px;
}
#submitted_button {
  /* background-color: blue;  */
  margin-top: 5px;
  display: inline-block;
  text-align: center;
  font-family: "GentiumBKR";
  width: 90px;
  height: 22px;
  font-size: 16px;
  background-color:  #886748;
  color: white;
  margin-left: 4px;
  position: relative;
}
@media screen and (max-width: 600px) {
  #submitted_button {
      /* background-color: blue; */ 
      /* position: absolute; */
      width: 105px;
      height: 28px;
      font-size: 22px;
      margin-left: 8px;
  }
}
#submitted_button:hover {
  background-color: #59451d;
  /* color: #886748;  */
}
#submitted_msg {
  font-family: "GentiumBKR";
  font-size: 14px;
  color: red;
  bottom: 0; /* Align the message to the bottom */
  left: 0; /* Align the message to the left */
  margin: 5px; /* Add margin for spacing */ 
  transform: translateX(-1%);
  position: absolute;

}
@media screen and (max-width: 600px) {
  #submitted_msg {
  font-size: 14px;
  bottom: 0; /* Align the message to the bottom */
  left: 0; /* Align the message to the left */
  margin: 5px; /* Add margin for spacing */
  transform: translateX(-1%); /* Move the message to the left edge */
  }
}
.footer {
  background-image: url("../images/seamless-frame_75res_full_FOOTER.png");
  background-color: transparent;
  background-size: contain;
  height: 40px;
  /* margin: 0px; */
  margin-top: 0%;
  margin-bottom: 15%;
}
.copyright {
  font-family: "Gentium-R";
  font-size: 15px;
  text-align: center;
  margin-bottom: 2%;
}
@media screen and (max-width: 600px) {
  .copyright {
    font-size: 24px;
    margin: 2.3%; 
  }
}
/* .logo_button_container {
  height: 10px;
  width: 10px;
  /* background-color: aqua; */


#logo_button_image {
  /* background-color: aqua; */
  height: 15px;
  width: 15px;
  margin-left: 95%;
  cursor: auto;
  margin-bottom: 3%; 
  /* bottom: 0; 
  right: 0; 
  position: fixed;  */
 
}
.policies {
  /* margin-top: 10%; */
  /* margin-bottom: 10%; */
  height: 25px;
  display: flex;
  justify-content: space-between;
  background-color: black;
  font-family: "Gentium-R";
  font-size: 12px;
  text-align: center;
}
@media screen and (max-width: 600px) {
  .policies {
    height: 60px;
    font-size: 16px;
  }
}
.policy-link {
  /* background-color: #59451d; */
  display: flex;
  align-items: center;
  /* height: 10%; */
  /* margin-top: 2%; */
  margin-bottom: 2px;
  margin-right: 50px;
  margin-left: 50px;
  text-decoration: none;
  color: white;
}
@media screen and (max-width: 600px) {
  .policy-link {
    margin-right: 4%;
    margin-left: 4%;
    margin-bottom: 8px;
  }
}
.policy-link:hover {
  text-decoration: none; /* Remove underline on hover */
  color: #59451d; /* Add a background color on hover if desired */
}



























