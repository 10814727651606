.bark-background_log_in {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-image: url('../images/bark_seamless_v2.jpg'); 
}
.pseudo-background_log_in {
    /* background-attachment:fixed; */
    margin: 0 auto; 
    /* display: flex;
    align-items: center; */
    /* justify-content: center; */
    position: fixed;
    width: 960px;
    height: 100%;
    z-index: 0;
    /* background-image: url('../images/godseye-sm.jpg'); */
    /* background-image: url('../images/background_1.png');  */
    background-image: url('../images/background_2.jpg');  
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top; 
}
.log_in_body {
    text-align: center;
    /* background-color: black; */
    /* margin-bottom: 20%;   */
    height: 60%;
    /* margin-top: 3%; */
    margin-left: 20%;
    margin-right: 20%;
    /* padding-top: 5%; */
    /* padding-bottom: 10%; */
    /* background-image: url('../images/Gods_Eye.svg'); */
    /* background-color: black */
    /* background-color: #d1c0ac; */
    z-index: 10;
}
.return_home {  
    margin-left: 85%;
    /* margin-right: 20%; */
    /* margin-bottom: 20%; */
    height:2%;
    width:2%;
    /* background-color: blue; */
}
#return_home_link {
    /* background-color: aquamarine; */
}
#close_image {
    height: 30px;
    width: 30px;
    /* background-color: blueviolet; */
    margin-top: 5%;
    margin-right: 5%;
    position: fixed; /* Position the element relative to the browser window */
    top: 0; /* Align to the top */
    right: 0; /* Align to the right */
}
.icon-wrapper_x {
    /* background-color: #BF9534; */
}
#header {
    /* background-color: #d1c0ac; */
    /* background-color: black; */
    width: 100%;
    margin-top: -20%;
}
#footer_img {
    /* background-color: aquamarine; */
    margin-bottom: -20%;

}
.admin_login_container {
    margin-top: -15%;
    /* background-color: blue; */
    /* background-color: #d1c0ac; */
}
.log_in_banner {
    font-size: 110%;
    margin-top: 1%;
    /* color: black; */
    /* background-color: #d1c0ac; */
}

#email {
    font-size: 120%;
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    color: black;
    /* background-color: blueviolet;     */
}
#email_admin {
    margin-top: 5%;
    margin-left: 10%;
    margin-right: 10%;
    /* background-color: #d1c0ac;     */
}
#password_admin {
    margin-top: 5%;
    margin-left: 10%;
    margin-right: 10%;
    /* background-color: #d1c0ac; */
}
#password {
    font-size: 120%;
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    color: black;
    /* background-color: blue */
}
.admin_account_apply {

}
.admin_account_apply:hover {
    color: gray
} 
#admin_account_apply_link {
    text-decoration: none; /* Remove underlines from links */
}
.submit {
    /* margin-top: -50%; */
    /* margin-left: 20%; */
    position: relative;
    background-color: #BF9534;
    border-radius: 16px; /* Round the edges with a value that suits your design */
    padding: 3px 6px; /* Increase padding to make the button larger */
    color: black;
    font-size: 90%; /* Set font size */
    text-align: center; /* Center the text within the button */
    cursor: pointer; /* Add a pointer cursor on hover */
    transition: background-color 0.3s; /* Add a smooth color transition */
}
.submit:hover {
    background-color: black;
    color: #BF9534;
}

